'use client';

import React, { useState } from 'react';
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from '@/components/ui/Button';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Validation from '@/components/form/Validation';
import FieldWrap from '@/components/form/FieldWrap';
import Icon from '@/components/icon/Icon';
import Input from '@/components/form/Input';
import Alert, { TAlertVariants } from '@/components/ui/Alert';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';

type TValues = {
	email: string;
	password: string;
};

const LoginPage = ({
	searchParams,
}: {
	searchParams?: Record<'callbackUrl' | 'error', string>;
}) => {
	const router = useRouter();

	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
		validate: (values: TValues) => {
			const errors: Partial<TValues> = {};

			if (!values.email) {
				errors.email = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
    onSubmit: async (values: TValues, { setStatus, resetForm }) => {
			const res = await signIn('credentials', {
				email: values.email,
				password: values.password,
				redirect: false,
			});
			
			if (res?.error) {
				resetForm();
				setStatus("Your email or password is incorrect. Please try again.");
			} else {
				router.push(searchParams?.callbackUrl ?? (process.env.NEXT_PUBLIC_URL as string));
			}
    }
  })

  return (
    <PageWrapper className='bg-white dark:bg-inherit'>
      <div className='container mx-auto flex h-full items-center justify-center'>
        <div className='flex max-w-sm flex-col gap-8'>
          <div>
						<Link href='/' aria-label='Logo'>
							Alfreddesk
						</Link>
					</div>
          <div>
						<span className='text-4xl font-semibold'>Sign in</span>
					</div>
          <form className='flex flex-col gap-4' noValidate>
						{formik.status && (
							<Alert
								className='border-transparent'
								color='red'
								icon='HeroFaceFrown'
								title='Oh! Something went wrong.'
								variant='solid'>
								{formik.status}
							</Alert>
						)}
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.email}
								invalidFeedback={formik.errors.email}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
									<Input
										dimension='lg'
										id='email'
										autoComplete='email'
										name='email'
										placeholder='Email'
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {formik.setStatus('')}}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.password}
								invalidFeedback={formik.errors.password}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
									lastSuffix={
										<Icon
											className='mx-2 cursor-pointer'
											icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
											onClick={() => {
												setPasswordShowStatus(!passwordShowStatus);
											}}
										/>
									}>
									<Input
										dimension='lg'
										type={passwordShowStatus ? 'text' : 'password'}
										autoComplete='current-password'
										id='password'
										name='password'
										placeholder='Password'
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {formik.setStatus('')}}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div>
							<Button
								size='lg'
								variant='solid'
								className='w-full font-semibold'
								onClick={() => formik.handleSubmit()}>
								Sign in
							</Button>
						</div>
          </form>
        </div>
      </div>
    </PageWrapper>
  )
}

export default LoginPage;